<template>
  <b-card-code>
    <div v-if="$can('create','Syllabus')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal()">
          {{ $t('Add New') }}
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="syllabuses"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'details'" v-html="props.row.details">
        </span>
        <span v-else-if="props.column.field === 'is_approved'">
          <b-badge v-if="props.row.is_approved===1" :variant="statusVariant(props.row.is_approved)">
            {{ status[props.row.is_approved] }}
          </b-badge>
          <b-badge v-else :variant="statusVariant(0)">
            {{ props.row.is_approved===0?'pending':'Declined' }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="!props.row.is_approved && (findAccess(props.row.id) || user.role_id===1)" variant="primary" @click="approve(props.row.id)">
                <feather-icon class="text-body " icon="CheckIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && (findAccess(props.row.id) || user.role_id===1)" variant="danger" @click="decliend(props.row.id)">
                <feather-icon class="text-body " icon="CrosshairIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && props.row.approvalable.length<1 && user.role_id===1" variant="secondary" @click="showModal2(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && findAccess(props.row.id)" variant="secondary" @click="showModal2(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="$can('remove','Syllabus')" :variant="'danger'" @click="remove(props.row.id)">
                <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
             hide-footer
             :title="'Add new Syllabus'"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_new" style="min-height: 350px">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Title') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Title')"
                  rules="required"
              >
                <b-form-input v-model="name" name="name" :placeholder="$t('Write your title here')"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="class"
                  rules="required"
              >
                <select name="classes_id" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{cls.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Version </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="version"
                  rules="required"
              >
                <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">{{version.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Student Group </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="student group"
                  rules="required"
              >
                <select name="student_group_id" v-model="student_group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(group, key) in student_groups" :value="group.id" :key="key">{{group.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Academic Year </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="academic year"
                  rules="required"
              >
                <select name="academic_year_id" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">{{aYear.year}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Subject </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="subject"
                  rules="required"
              >
                <select name="subject_id" v-model="subject_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(subject, key) in subjects" :value="subject.id" :key="key">{{subject.name+' '+subject.code}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-card-text>
                <span>Need Approval?</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="need approval"
                  rules="required"
              >
                <b-form-radio-group
                    v-model="is_approved"
                    :options="options"
                    stacked
                    name="is_approved"
                >
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4" v-if="is_approved===0">
              <b-card-text>
                <span>Select Employee </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="employee"
                  rules="required"
              >
                <select v-model="employee_id" name="employee_id" class="form-control"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option  :value="null">Select One</option>
                  <option v-for="(emp, key) in employees" :key="key" :id="emp.id" :value="emp.id">{{ employeeNameById(emp.id) +' '+ employeeIdById(emp.id)}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="12">
              <b-card-text>
                <span>Syllabuses Details</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="syllabus"
                  rules="required"
              >
                <vue-editor name="details" :editorToolbar="customToolbar" rows="5" v-model="details" placeholder="write here syllabus details"></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit button -->
            <b-col md="4" class="mt-2">
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="addNew"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal ref="my-modal2"
             hide-footer size="lg"
             :title="'Foreword Information'"
    >
      <b-row>
        <b-col md="12">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Employee ID</b-th>
              <b-th class="text-center"  :colspan="1">Employee Name</b-th>
              <b-th class="text-center"  :colspan="1">Comment</b-th>
              <b-th class="text-center"  :colspan="1">Status</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(grade,k) in selected_row.approvalable" :key="k">
                <b-th class="text-center">{{ employeeIdById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ employeeNameById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ grade.comment }}</b-th>
                <b-th class="text-center">{{ status3[parseInt(grade.status)] }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="leave_request">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="employee"
                    rules="required"
                >
                  <select name="employee_id" v-model="employee_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(emp, key) in employees" :value="emp.id" :key="key">{{employeeNameById(emp.id)}}- {{emp.employee_id}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="comment"
                    rules="required"
                >
                  <b-form-textarea
                      v-model="comment"
                      name="comment"
                      :state="errors.length > 0 ? false:null"
                      placeholder="comment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                {{ $t('Submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,BRow, BCol,
  BCardText,BFormRadioGroup,BModal,BFormTextarea,
  BForm,BTableSimple,BThead,BTr,BTh,BTbody,
} from 'bootstrap-vue'
import {required,integer} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
import { VueEditor } from "vue2-editor";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'Syllabus',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,BModal,BFormTextarea,
    BForm,BTableSimple,BThead,BTr,BTh,BTbody,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BRow, BCol,BCardText,BFormRadioGroup,
    VueEditor, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ],
      is_approved: 1,
      options: [
        { text: 'Yes', value: 0 },
        { text: 'No', value: 1 },
      ],
      required,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Class',
          field: 'classes.name',
        },
        {
          label: 'Version',
          field: 'version.name',
        },
        {
          label: 'Student Group',
          field: 'student_group.name',
        },
        {
          label: 'Academic year',
          field: 'academic_year.year',
        },
        {
          label: 'Syllabus',
          field: 'details',
        },
        {
          label: 'Status',
          field: 'is_approved',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: {
        1: 'Approved',
        0: 'Pending',
      },
      status3:{
        0:'Pending',
        1:'Approved',
        2:'Foreword',
        3:'Declined'
      },
      integer,
      name:'',
      classes:[],
      versions:[],
      student_groups:[],
      student_group_id:null,
      version_id:null,
      classes_id:null,
      academic_year_id:null,
      subject_id:null,
      details:'',
      employee_id:null,
      comment:'',
      columns10: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Class',
          field: 'Class',
        },
        {
          label: 'Version',
          field: 'Version',
        },
        {
          label: 'Student Group',
          field: 'StudentGroup',
        },
        {
          label: 'Academic year',
          field: 'AcademicYear',
        },
        {
          label: 'Syllabus',
          field: 'Syllabus',
        },
        {
          label: 'Status',
          field: 'Status',
        }
      ],
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_SYLLABUSES_LIST');
    this.getAll();
    this.$store.dispatch('GET_ACTIVE_SUBJECT');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.employees.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE');
  },
  methods:{
    pdfGenerate() {
      let title = "Syllabuses";
      let clm = ['Name','Class','Version','StudentGroup','AcademicYear','Syllabus','Status'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'fee_option.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
    showModal2(row) {
      this.selected_row=row;
      this.$refs['my-modal2'].show();
    },
    hideModal2() {
      this.$refs['my-modal2'].hide();
    },
    /*
    *
    * method for approve item
    * */
    approve(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to approve!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/syllabus/approve${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_SYLLABUSES_LIST');
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },

    /*
    * method for remove item
    * */
    remove(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to remove this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/syllabus/remove${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_SYLLABUSES_LIST');
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    /*
    * method for show modal
    * */
    showModal() {
      this.$refs['my-modal'].show();
    },
    /*
    * method for hide modal
    * */
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    /*
    * method for store item
    * */
    addNew(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_new);
          data.append('details',this.details);
          apiCall.post('/syllabus/store',data).then((response)=>{
            if (response.data.status === 'success'){
              this.$toaster.success(response.data.message);
              this.details='';
              this.name='';
              this.subject_id=null;
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SYLLABUSES_LIST');
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            if (error.response.data.status ==422){
              let mainData = '';
              error.response.data.errors.forEach((item)=>{
                mainData += item + "<br/>";
              });
              this.$toaster.error(mainData);
            }else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    /*
    * method for get all active class wise version,student groups
    * */
    async getAll(){
      await apiCall.get('/get/active/class/with/v/s/groups').then((response)=>{
        this.classes = response.data;
      }).catch(()=>{
        this.classes=[];
      });
    },
    decliend(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to declined this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, declined it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/syllabus/declined${id}`).then((response)=>{
            this.$store.dispatch('GET_ALL_SYLLABUSES_LIST');
            this.$swal({
              icon: 'success',
              title: 'Status Change!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.leave_request);
          apiCall.post(`/syllabus/forward${this.selected_row.id}`,data).then((response)=>{
            this.hideModal2();
            this.$toaster.success(response.data.message);
            this.selected_row={};
            this.employee_id=null;
            this.comment='';
            this.$store.dispatch('GET_ALL_SYLLABUSES_LIST');
          }).catch((error)=>{
            if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    findAccess(id){
      let ff = this.syllabuses.find(item=>item.id===parseInt(id));
      if(ff){
        if(ff.approvalable.length<1) return false;
        else return ff.approvalable[ff.approvalable.length -1].employee_id===this.user.userable.id;
      }
      else return false;
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['syllabuses','academic_years','employees','employeeIdById','employeeNameById']),
    getData() {
      return this.syllabuses.map(item => ({
        Name: item.name ? item.name : '',
        Class: item.classes ? item.classes.name : '',
        Version: item.version ? item.version.name : '',
        StudentGroup: item.student_group ? item.student_group.name : '',
        AcademicYear: item.academic_year ? item.academic_year.year : '',
        Syllabus: item.details ? item.details : '',
        Status: item.is_approved == 1 ? 'Approved' : (item.is_approved == 2 ? 'Declined' : (!item.is_approved ? 'Pending' : '')),
      }));
    },
    subjects(){
      let sub = this.$store.getters.active_subjects;
      sub = sub.map((item)=>{
        if (item.classes_id == this.classes_id && item.version_id == this.version_id && item.student_group_id == this.student_group_id) return item;
      }).filter(Boolean);
      return sub;
    },
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    },
  },
  watch:{
    /*watcher for class on change*/
    classes_id(){
      let s_classes = this.classes.find(item=>item.id===this.classes_id);
      this.version_id=null;
      this.student_group_id=null;
      this.subject_id=null;
      if (s_classes){
        this.versions = s_classes.versions??[];
        this.student_groups = s_classes.student_groups??[];
      }else {
        this.versions =[];
        this.student_groups =[];
      }
    },
    version_id(){
      this.subject_id=null;
    },
    student_group_id(){
      this.subject_id=null;
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>